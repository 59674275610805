<template>
  <div>
    <v-form
      class="mt-6"
      @submit.prevent="submit"
      lazy-validation
      ref="form"
      v-model="valid">
  
      <div>
        <div class="my-2 font-weight-medium">Email</div>
        <v-text-field
          v-model="form.email"
          placeholder="Input Email"
          :rules="emailRules"
          class="rounded-lg"
          type="email"
          dense
          required
          outlined
          hide-details="auto">
        </v-text-field>
      </div>
  
      <v-alert
        v-if="response.message"
        dense
        text
        class="mt-3"
        :type="response.type"
        v-html="response.message">
      </v-alert>
  
      <v-btn
        class="mt-8 rounded-lg"
        type="submit"
        color="primary"
        block
        small
        depressed
        :loading="process.run"
        :disabled="process.run">
        Kirim Ke Email
      </v-btn>
  
      <div class="mt-4 text-center fs-12">
        <div class="my-2 font-weight-medium text-gray-500">Sudah ingat password Anda ? 
          <router-link to="/auth/login" class="primary--text">Masuk disini</router-link>
        </div>
      </div>
  
    </v-form>

    <v-dialog v-model="dialog.success" width="500">
      <v-card class="pa-3 text-center rounded-lg">
        <v-card-title class="d-flex flex-column justify-center align-center headline">
          Link Set Password berhasil dikirim
        </v-card-title>
        <v-card-text>
          <span>Cek inbox/spam email anda kemudian buka link yang telah kami kirimkan untuk melanjutkan proses</span>
        </v-card-text>
        <v-card-actions class="d-flex flex-column">
          <v-btn
            color="primary"
            href="https://mail.google.com/mail/u/0/#inbox"
            target="_blank"
            class="mt-8 rounded-lg"
            small
            depressed
            block
            :disabled="process.run"
            @click="redirectAfterSubmit"
          >
            Cek Email
          </v-btn>
          <div class="caption mt-3 cursor-pointer">
            <span class="text-gray-500">Belum menerima email ?</span>
            <v-btn
              text
              x-small 
              :loading="process.run"
              :disabled="process.run"
              @click="() => {
                if (form.email) {
                  this.submit()
                }else {
                  this.dialog.success = false
                  this.$refs.form.reset()
                }
              }" 
              class="primary--text font-weight-bold ml-1">
              Klik untuk kirim ulang
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import { LMS_API } from '@/constants/api'

export default {
  data() {
    return {
      valid: true,
      form: {
        email: "",
      },
      process: {
        run: false
      },
      emailRules: [
        (v) => !!v || "Email tidak boleh kosong",
        (v) => /.+@.+\..+/.test(v) || "Email tidak valid",
      ],
      dialog: {
        success: false,
      },

      response: {
        message: "",
        type: "",
      }
    };
  },
  methods: {
    async submit() {
      this.process.run = true
      await this.axios.post(`${LMS_API.AUTH.FORGOT_PASSWORD}`, {
        email: this.form.email
      }).then(() => {
        this.dialog.success = true

        this.process.run = false
      }).catch((err) => {
        this.process.run = false
        this.response.message = err.response.data.message
        this.response.type = "error"
      })
    },
  }
}
</script>